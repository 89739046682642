import { useState } from 'react'
import GeneratedImage from './components/GeneratedImage'
import Switch from './components/Switch'
import Button from './components/Button'
import Slider from './components/Slider'

const App = () => {
    const randomizeSeed = () => {
      return Math.floor(Math.random() * 1e10)
    }

    const [seed, setSeed] = useState(randomizeSeed())
    const [ratio, setRatio] = useState(2)
    const [boomerize, setBoomerize] = useState(false)
    const [friendlyFace, setFriendlyFace] = useState(false)
    const [flair, setFlair] = useState(false)

    const toggleBoomerize = (e) => {
        setBoomerize(e.target.checked)
    }

    const toggleFriendlyFace = (e) => {
        setFriendlyFace(e.target.checked)
    }

    const toggleFlair = (e) => {
        setFlair(e.target.checked)
    }

    return (
    <div className="App">
      <main className="container">

        <img style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
             }}
             alt="Livscoachen"
             src="livscoachen.png"
        />

        <GeneratedImage options={{ seed, ratio, boomerize, friendlyFace, flair }} />

        <Slider ratio={ ratio } onSliderChange={ setRatio } />

        <div className="grid">
          <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "20px"
          }}>
            <Switch name="boomerize" 
                    label="OK Boomer"
                    value={ boomerize }
                    toggle={ toggleBoomerize }
            />
            <Switch name="friendlyFace"
                    label="Känt ansikte"
                    value={ friendlyFace }
                    toggle={ toggleFriendlyFace }
            />
            <Switch name="flair"
                    label="Glimten i ögat"
                    value = { flair }
                    toggle={ toggleFlair }
            />
          </div>
        </div>

        <div className="grid">
          <div className="grid"
               style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
          }}>
            <Button text="Ge mig en till"
                    onClick={ () => setSeed(randomizeSeed()) }
            />

            <Button text="Slumpa"
                    onClick={ () => {
                        setBoomerize(Math.random() < 0.5)
                        setFriendlyFace(Math.random() < 0.5)
                        setFlair(Math.random() < 0.5)
                        setRatio(Math.round(Math.random() * 10))
                        setSeed(randomizeSeed())
                    }}
            />
          </div>
        </div>

      </main>
    </div>
    )
}

export default App
