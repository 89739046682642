import { useState, useEffect } from "react"

const GeneratedImage = ({ options }) => {
  const baseUrl = 'https://api.livscoa.ch/generator/' 

  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [url, setUrl] = useState()
  const [seed, setSeed] = useState()

	useEffect(() => {
        if (seed !== options.seed) {
            setSeed(options.seed)
            const params = new URLSearchParams(options)

            setIsImageLoaded(false)
            setUrl(`${baseUrl}?${params}`)
        }
	}, [options, seed])


  return (
    <div className="grid">
      <article>
        { !isImageLoaded && <GeneratedImageLoading /> }
        <img alt="Livsvisdom"
             style={{
               width: '100%',
               display: isImageLoaded ? 'block' : 'none'
             }}
             src={ url }
             onLoad={ () => setIsImageLoaded(true) }
        />
      </article>
    </div>
  )
}

function GeneratedImageLoading() {
  return (
    <img alt="Laddar..."
         src="loading.gif"
         style={{ width: '100%' }}
    />
  )
}

export default GeneratedImage
