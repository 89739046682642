const Slider = ({ ratio, onSliderChange }) => {
  return (
    <div>
      <div className="grid">
        <div style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "5px"
        }}>
          <span>Livscoach</span>
          <span style={{ justifySelf: "end" }}>Ordspråk</span>
        </div>
      </div>

      <div className="grid">
        <input type="range" 
               min={ 0 } 
               max={ 10 } 
               value={ ratio }
               onChange={ (e) => onSliderChange(e.target.value) }
        /> 
      </div>
    </div>
  )
}

export default Slider
