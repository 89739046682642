const Switch = ({ name, label, value, toggle }) => {
  return (
    <fieldset>
      <label htmlFor={ name }>
        <input type="checkbox"
               name={ name }
               checked={ value }
               onChange={ (e) => toggle(e) }
               role="switch" />
        { label }
      </label>
    </fieldset>
  )
}

export default Switch
